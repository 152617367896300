<template>
    <div class="upload-banner">
        <el-upload
                :action="actionUrl"
                :auto-upload="true"
                :file-list="fileList"
                :limit="5"
                :on-exceed="handleExceed"
                :on-success="handleSuccess"
                accept=".jpg,.png"
                list-type="picture-card"
                name="file"
                ref="upload"
        >
            <i class="el-icon-plus" slot="default"></i>
            <div slot="file" slot-scope="{file}">
                <img
                        :src="file.url"
                        alt="" class="el-upload-list__item-thumbnail"
                >
                <span class="el-upload-list__item-actions">
        <span
                @click="handlePictureCardPreview(file)"
                class="el-upload-list__item-preview"
        >
          <i class="el-icon-zoom-in"></i>
        </span>
        <span
                @click="handleRemove(file)"
                class="el-upload-list__item-delete"
                v-if="!disabled"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
            </div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
            <img :src="dialogImageUrl" alt="" width="100%">
        </el-dialog>
    </div>
</template>

<script>
    import {uploadingProjectImg} from '@/api/uploadApi'

    export default {
        data() {
            return {
                fileList: [],
                actionUrl: uploadingProjectImg,
                dialogImageUrl: '',
                dialogVisible: false,
                disabled: false,
                bannerUrl: [],
            };
        },
        props: ["list"],
        watch: {
            // list: function(newUrl, oldUrl) {
            //   console.log("list变了吗",newUrl, oldUrl)
            //   this.bannerUrl=JSON.parse(JSON.stringify(this.list));
            //   // this.fileList=[];
            //   let list=[];
            //   newUrl.forEach(item => {
            //     list.push({ url: item });
            //   });
            //   // this.fileList=list;
            // }
        },
        methods: {
            setList(list) {
                let list1 = [];
                list.forEach(item => {
                    list1.push({url: item});
                });
                this.fileList = list1;
                this.bannerUrl = list1;
            },
            //获取图片地址集合
            getUrls() {
                let list = [];
                this.bannerUrl.forEach(function (e) {
                    list.push(e.url)
                })
                return list.join(",");
            },
            //清空上传的图片
            clear() {
                this.$refs.upload.clearFiles();
            },
            handleRemove(file) {
                let fileList = this.$refs.upload.uploadFiles;
                let index = fileList.findIndex(fileItem => {
                    return fileItem.uid === file.uid
                });
                fileList.splice(index, 1);
                this.bannerUrl.splice(index, 1);

            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            handleExceed(files, fileList) {
                this.$message({message: this.$t('myProject.overLimit'), type: 'warning'});
            },
            handleSuccess(response, file, fileList) {
                if (response.code == 200) {
                    this.bannerUrl.push({url: response.data.path});
                } else {
                    this.$message({message: response.msg, type: 'error'});
                    let fileList = this.$refs.upload.uploadFiles;
                    let index = fileList.findIndex(fileItem => {
                        return fileItem.uid === file.uid
                    });
                    fileList.splice(index, 1);
                }
            }

        }
    }
</script>

<style scoped>

</style>
