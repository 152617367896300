import * as Quill from 'quill'  //引入编辑器
import {ImageResize} from 'quill-image-resize-module';

Quill.register('modules/imageResize', ImageResize);
//quill编辑器的字体
let fonts = ['andale-mono', 'arial', 'arial-black', 'book-antiqua', 'impact', 'SimSun', 'SimHei', 'Microsoft-YaHei', 'Arial', 'Times-New-Roman', 'sans-serif'];
let Font = Quill.import('formats/font');
Font.whitelist = fonts; //将字体加入到白名单
Quill.register(Font, true);

// 工具栏配置
const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
    ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
    [{header: 1}, {header: 2}], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
    [{list: "ordered"}, {list: "bullet"}], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
    // [{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
    [{indent: "-1"}, {indent: "+1"}], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
    [{direction: "rtl"}], // 文本方向-----[{'direction': 'rtl'}]
    [{size: ["small", false, "large", "huge"]}], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
    [{header: [1, 2, 3, 4, 5, 6, false]}], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
    [{color: []}, {background: []}], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
    [{font: fonts}], // 字体种类-----[{ font: [] }]
    [{align: []}], // 对齐方式-----[{ align: [] }]
    ["clean"], // 清除文本格式-----['clean']
    ["image", "video"] // 链接、图片、视频-----['link', 'image', 'video']
];

export const quillMixin = {
    data() {
        return {
            // 富文本编辑器默认内容
            richContent: '<h2>I am Example</h2>',
            //富文本编辑器配置
            editorOption: {
                //  富文本编辑器配置
                modules: {
                    toolbar: toolbarOptions,
                    imageResize: {
                        // See optional "config" below
                    }
                },
                theme: "snow",
                placeholder: "Please enter the text"//请输入正文
            }
        };
    },
    mounted() {

    },
    methods: {
        //失去焦点事件
        onEditorBlur(quill) {
            console.log('editor blur!', quill)
        },
        //获得焦点事件
        onEditorFocus(quill) {
            console.log('editor focus!', quill)
        },
        // 准备富文本编辑器
        onEditorReady(quill) {
            console.log('editor ready!', quill)
        },
        //内容改变事件
        onEditorChange({quill, html, text}) {
            console.log('editor change!', quill, html, text)
            this.content = html
        }
    }
}
