import axios from "axios";
import {uploadingForRichText} from "@/api/uploadApi";
import Editor from "@tinymce/tinymce-vue";

export const tinyMixin = {
    data() {
        return {
            apikey: "4qohlzjq79bkpxerkgfybbs3dj58cy4uzceyfd2g6l3oapgn",
            //tinymce初始化
            editorInit: {
                // language_url: '/static/tinymce/zh_CN.js',//引入汉化包
                // language: 'zh_CN',//使用中文
                selector: 'textarea#full-featured',
                plugins: 'print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap tinycomments mentions quickbars linkchecker emoticons advtable',
                mobile: {
                    plugins: 'print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker textpattern noneditable help formatpainter pageembed charmap mentions quickbars linkchecker emoticons advtable'
                },
                menubar: false,//菜单栏不显示
                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat |  | charmap emoticons | fullscreen  preview  print | image media pageembed template link anchor codesample | a11ycheck ltr rtl |',
                branding: false,    //隐藏tinymce右下角水印
                autosave_ask_before_unload: true,
                autosave_interval: '30s',
                autosave_prefix: '{path}{query}-{id}-',
                autosave_restore_when_empty: false,
                autosave_retention: '2m',
                image_advtab: true,
                link_list: [
                    {title: 'My page 1', value: 'https://www.tiny.cloud'},
                    {title: 'My page 2', value: 'http://www.moxiecode.com'}
                ],
                image_list: [
                    {
                        title: 'My page 1',
                        value: 'https://pic1.zhimg.com/80/v2-3402b0dbf1b1845ee6f936099156226c_720w.jpg'
                    },
                    {
                        title: 'My page 2',
                        value: 'https://pic1.zhimg.com/80/v2-3402b0dbf1b1845ee6f936099156226c_720w.jpg'
                    }
                ],
                image_class_list: [
                    {title: 'None', value: ''},
                    {title: 'Some class', value: 'class-name'}
                ],
                importcss_append: true,
                template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                height: 667,
                image_caption: true,
                quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                noneditable_noneditable_class: 'mceNonEditable',
                toolbar_mode: 'sliding',
                spellchecker_ignore_list: ['Ephox', 'Moxiecode'],
                tinycomments_mode: 'embedded',
                content_style: '.mymention{ color: gray; }',
                contextmenu: 'link image imagetools table configurepermanentpen',
                a11y_advanced_options: true,
                images_upload_handler: (blobInfo, success, failure) => {//上传图片回调函数
                    this.imgUpload(blobInfo, success, failure)
                },
            },
            editContent: '',   //内容
        };
    },
    components: {
        'editor': Editor

    },
    methods: {
        imgUpload(blobInfo, success, failure) {

            let formData = new FormData()
            formData.append('file', blobInfo.blob())

            axios.post(uploadingForRichText, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then(res => {
                if (res.data.code == 200) {
                    success(res.data.data[0].url);
                } else {
                    failure(res.data.msg);
                }

            }).catch(err => {
                failure('fail: ' + err);
            });
        },
    }
}
